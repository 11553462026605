.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;

  pointer-events: all;
}

.iframe {
  min-height: 678px;
}
